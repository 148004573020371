import React from "react"
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-deferred';


class MultiBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartReference = React.createRef();
  }
 
  componentDidMount() {
    //console.log(this.chartReference); // returns a Chart.js instance reference
  }
 
  render() {
    const data_sets = this.props.data.lineChart;
    // const data_set_count = data_sets.dataSets.length;	
    
    // const data = this.props.data;
    const x_label = data_sets.xLabel;
    const x_label_tool = data_sets.tooltipXLabelOptions;
    const x_unit = data_sets.xUnitLabel;
    const x_unit_tool = data_sets.tooltipXUnitOptions;
    const y_label = data_sets.yLabel;
    const y_label_tool = data_sets.tooltipYLabelOptions;
    const y_unit = data_sets.yUnitLabel;
    const y_unit_tool = data_sets.tooltipYUnitOptions;
    const x_label_tool_custom = data_sets.xLabelCustomTooltip    
    const x_unit_tool_custom = data_sets.xUnitLabelCustomTooltip
    const y_label_tool_custom = data_sets.yLabelCustomTooltip
    const y_unit_tool_custom = data_sets.yUnitLabelCustomTooltip
  
    
    const theLabels = [];
    data_sets.dataSets[0].dataValues.map((label) => {
      return(
        theLabels.push(label.xDataValue)
      )
    })
    
    const theData = [];
    data_sets.dataSets.forEach((set) => {
      const newData = [];
      set.dataValues.forEach((value) => {
        newData.push(value.dataValue);
      })
      theData.push(newData);
    })


    const the_datasets = [];
    data_sets.dataSets.forEach((dataset, i) => {
      the_datasets[i] = {
        label: dataset.dataSetTitle,
        backgroundColor:dataset.dataSetColour,
        borderColor:dataset.dataSetColour,
        data:theData[i],
        fill:false,
      }
    });
    
    const getData = () => {
      
      return{
        datasets: the_datasets,
        labels: theLabels
        
        // data:values
      }
    }

    
    
    
    const scales = {
      yAxes: [{
        scaleLabel:{
          display:false,
          labelString: y_label
        },
        ticks: {
          beginAtZero: true,
          callback: function(value, index, values) {
              return value;
          }
        }
      }],
      xAxes: [{
        scaleLabel:{
          display:false,
          labelString: x_label
        },
        ticks: {
          beginAtZero: true,
          callback: function(value, index, values) {
            return value;
          }
        }
      }] 
    }


    const options = {
      // legend: {
      //   display: false
      // },
      scales,
      plugins: {
        deferred: {
          xOffset: 150,   // defer until 150px of the canvas width are inside the viewport
          yOffset: '50%', // defer until 50% of the canvas height are inside the viewport
          delay: 500      // delay of 500 ms after the canvas is considered inside the viewport
        }
      },
      responsive:true,
      maintainAspectRatio: false, 
      tooltips: {
        custom: function(tooltip) {
          if (!tooltip) return;
          // disable displaying the color box;
          tooltip.displayColors = false;
        },
        callbacks: {
          // use label callback to return the desired label
          label: function(tooltipItem, data) {

            let x_prelabel,
            x_postlabel,
            y_prelabel,
            y_postlabel,
            x_label_combined,
            y_label_combined;
            
            // X BEFORE
            if(x_label_tool === 'Before Data'){
              x_prelabel = x_label;
              x_label_combined = x_prelabel+tooltipItem.xLabel;
            }

            if(x_unit_tool === 'Before Data'){
              x_prelabel = x_unit;
              x_label_combined = x_prelabel+" "+tooltipItem.xLabel;
            }

            // X AFTER
            if(x_label_tool === 'After Data'){
              x_postlabel = x_label;
              x_label_combined = tooltipItem.xLabel+" "+x_postlabel;
            }

            if(x_unit_tool === 'After Data'){
              x_postlabel = x_unit;
              x_label_combined = tooltipItem.xLabel+" "+x_postlabel;
            }

            // Y BEFORE
            if(y_label_tool === 'Before Data'){
              y_prelabel = y_label;
              y_label_combined = y_prelabel+" "+tooltipItem.yLabel;
            }

            if(y_unit_tool === 'Before Data'){
              y_prelabel = y_unit;
              if(y_unit === '£'){
                y_label_combined = y_prelabel+tooltipItem.yLabel.toLocaleString();
              }else{
                y_label_combined = y_prelabel+" "+tooltipItem.yLabel;
              }
            }

            // Y AFTER
            if(y_label_tool === 'After Data'){
              y_postlabel = y_label;
              y_label_combined = tooltipItem.yLabel+" "+y_postlabel;
            }

            if(y_unit_tool === 'After Data'){
              y_postlabel = y_unit;
              y_label_combined = tooltipItem.yLabel+" "+y_postlabel;
            }


            // X CUSTOM BEFORE
            if(x_label_tool === 'Custom Before'){
              if(x_label_tool_custom === 'Use data title'){
                y_postlabel = data.datasets[tooltipItem.datasetIndex].label;
              }else{
                x_postlabel = x_label_tool_custom;
              }
              x_label_combined = tooltipItem.xLabel+" "+x_postlabel;
            }

            if(x_unit_tool === 'Custom Before'){
              if(x_unit_tool_custom === 'Use data title'){
                y_postlabel = data.datasets[tooltipItem.datasetIndex].label;
              }else{
                x_postlabel = x_unit_tool_custom;
              }
              x_label_combined = tooltipItem.xLabel+" "+x_postlabel;
            }

            // Y CUSTOM BEFORE
            if(y_label_tool === 'Custom Before'){
              if(y_label_tool_custom === 'Use data title'){
                y_postlabel = data.datasets[tooltipItem.datasetIndex].label;
              }else{
                y_postlabel = y_label_tool_custom;
              }
              y_label_combined = tooltipItem.yLabel+" "+y_postlabel;
            }

            if(y_unit_tool === 'Custom Before'){
              if(y_unit_tool_custom === 'Use data title'){
                y_postlabel = data.datasets[tooltipItem.datasetIndex].label;
              }else{
                y_postlabel = y_unit_tool_custom;
              }
              y_label_combined = tooltipItem.yLabel+" "+y_postlabel;
            }

            // X CUSTOM AFTER
            if(x_label_tool === 'Custom After'){
              if(x_label_tool_custom === 'Use data title'){
                y_postlabel = data.datasets[tooltipItem.datasetIndex].label;
              }else{
                x_postlabel = x_label_tool_custom;
              }
              x_label_combined = tooltipItem.xLabel+" "+x_postlabel;
            }

            if(x_unit_tool === 'Custom After'){
              if(x_unit_tool_custom === 'Use data title'){
                y_postlabel = data.datasets[tooltipItem.datasetIndex].label;
              }else{
                x_postlabel = x_unit_tool_custom;
              }
              x_label_combined = tooltipItem.xLabel+" "+x_postlabel;
            }

            // Y CUSTOM AFTER
            if(y_label_tool === 'Custom After'){
              if(y_label_tool_custom === 'Use data title'){
                y_postlabel = data.datasets[tooltipItem.datasetIndex].label;
              }else{
                y_postlabel = y_label_tool_custom;
              }
              y_label_combined = tooltipItem.yLabel+" "+y_postlabel;
            }

            if(y_unit_tool === 'Custom After'){
              if(y_unit_tool_custom === 'Use data title'){
                y_postlabel = data.datasets[tooltipItem.datasetIndex].label;
              }else{
                y_postlabel = y_unit_tool_custom;
              }
              y_label_combined = tooltipItem.yLabel+" "+y_postlabel;
            }

            // UNDEFINED
            if(x_label_combined === undefined){
              x_label_combined = tooltipItem.xLabel;
            }

            if(y_label_combined === undefined){
              y_label_combined = tooltipItem.yLabel;
            }

            let label = x_label_combined + " : " + y_label_combined;

      

            return label;
          
          },
          // remove title
          title: function(tooltipItem, data) {
            return;
          }
        }
      }
    };

    const chartData = getData();

    return (

      <>
      <Line ref={this.chartReference} data={chartData} options={options} scales={scales} className="lineChart"/>
    </>
    )
  }
}



export default MultiBarChart