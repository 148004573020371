import React from "react"
import parse from 'html-react-parser';
import styled from 'styled-components';
import {Link} from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';
import MultiLineChart from "../../charts/MultiLineChart";

const ContainsCustomColours = styled.div`

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    background:var(--black);
    background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ul{
    li:before{
      background-color: ${(props) => props.customColour2} !important; 
    }

    &.styled_bullet_list{
      color: ${(props) => props.customColour2} !important; 
    }
  }

  .x-label{
    margin-bottom:2rem;
    left:0;
    width: 100%;
    margin-top:1rem;
    }

    .y-label{
    left: -2rem;
    writing-mode: vertical-lr;
    height: 100%;
    transform: rotate(180deg);
    position: absolute;
    }

    .x-label,
    .y-label{
    text-align: center;
    font-size: 1.3rem;
    color: grey;;
    position: relative;
    }
`

const ChartWrapper = styled.div`
position: relative; 
height: 50vh; 
max-height: 500px;
`

class ServiceLineChartTextButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInview:false
    }
  }
 
  componentDidMount(props) {
    // console.log('test', this.props.isInView)
  }
 
  render() {
    const data = this.props;
    const customColour1 = this.props.customColour1;
    const customColour2 = this.props.customColour2;
    const isInView = this.props.isInView;

    const inviewOnChange = (isVisible) => {
      this.setState({
        isInview: isVisible
      })
    }

    let classList = [
      'chart-text', 
      'contains-custom-colour',
      'section'
    ]; 

  
    const text = data.text;
    const button = data.button;
    const x_label = data.lineChart.xLabel;
    const y_label = data.lineChart.yLabel;
    return (
      <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!this.state.isInview}  onChange={inviewOnChange}>
        {({isVisible}) =>
          <section className={`${classList.join(" ")} ${this.state.isInview === true ? 'is_inview' : ''}`} data-bg-shape="show_shape">
            <ContainsCustomColours customColour1={customColour1} customColour2={customColour2} className="wrapper">
              
              <ChartWrapper className={text ? 'chart_wrapper' : 'chart_wrapper full-width'}>
                <div className="y-label label">{y_label}</div>
                <MultiLineChart data={data} />
                <div className="x-label label">{x_label}</div>
              </ChartWrapper>
              
              {text ? 
                <div className="text-area no-margin">
                  { parse(text) }
                  {button.buttonText ? <Link to={button.buttonLink.link} className="btn btn-green-grad">{button.buttonText}</Link> : "" }
                </div>
              : ''}
            </ContainsCustomColours>
          </section>
        
        }
      </VisibilitySensor>

    )
  }
}



export default ServiceLineChartTextButton